body {
  background: {
    image: url("./assets/Background@2x.jpg");
    size: cover;
    repeat: no-repeat;
    attachment: fixed; }
  margin: 0px;
  padding: {
    bottom: 50px; }
  font: {
    family: "Roboto", sans-serif;
    size: 16px;
 } }    // weight: 400

.top-card {
  border-radius: 0 0 10px 10px;
  padding: {
    left: 20px;
    right: 20px;
    top: 10px;
    bottom: 10px; }
  background: {
    color: rgba(255, 255, 255, 1); }

  .title {
    text-align: center;
    font: {
      size: 34px;
      family: Gotham;
      weight: 900; }
    letter-spacing: 3.4px; }

  .subtitle {
    text-align: center;
    font: {
      font-family: Gotham;
      font-size: 12px;
      weight: 400; } } }


.button-card {
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 4em 0px rgba(0, 0, 0, 0.4);
    transform: scale(1.01); } }

.rounded-card {
  border-radius: 20px;
  background: {
    color: rgba(255, 255, 255, 1); }
  padding: 2rem; }

.transparent-card {
  border-radius: 20px;
  background: {
    color: rgba(255, 255, 255, 1); }
  padding: 2rem; }

.button {
  cursor: pointer;
  &:hover {
    background: {
      color: rgba(255, 255, 255, 1); } } }


main {
  margin: {
    top: 30px; } }

.MuiPaper-root {
  &.selected-card {
    background: {
      color: rgba(63, 81, 181, 0.1) !important; }
    // &:hover
    //   background:
 } }    //     color: rgba(63, 81, 181, 0.01) !important

.MuiPaper-root {
  &.selectable {
    cursor: pointer;
    // &:hover
    //   background:
 } }    //     color: rgba(63, 81, 181, 0.01) !important

.align-right {
  text-align: right; }
