.App-header {
  margin: {
    top: 0; }
  padding: {
    left: 10ex;
    right: 10ex; }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .logo {
    height: 60px;
    // margin: 20px
    // border-radius: 0 0 10px 10px
    // background:
    //   color: rgba(0, 137, 255, 1)

 } }    // color: white


// .selected
//   path
//     stroke: rgba(0, 137, 255, 1)

.hidden {
  display: none !important; }
