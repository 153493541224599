.basic-info {
  & .MuiTextField-root {
    margin: {
      top: 1ex; } } }

.demographics {
  // display: -webkit-flex
  // display: flex
  // -webkit-align-items: end
  // align-items: end
  vertical-align: "bottom";

  & .MuiTextField-root {
    width: 12ch;
    margin: {
      right: 2ex;
 } }      // top: 1ex
  & .MuiSelect-root {
    width: 20ch; } }

.demographic-selector {
  margin: {
    top: 1ex; } }
