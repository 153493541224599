.user-profile-block {
  display: flex;
  align-items: center;

  .user-profile {
    display: flex;
    align-items: center;

    .user-name-block {
      margin: {
        left: 1ex;
        right: 5ex; }

      .user-name {
        font-size: 1rem;
        margin-bottom: 0.1rem; }

      .user-welcome {
        font: {
          size: 0.8rem; }
 } }        // color: #777

    .large {
      width: 40px;
      height: 40px; } } }

.assistance {
  color: #fff !important; }
