.subtext {
  font: {
    size: 0.6rem; }
  margin-right: 1ex; }

.price {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font: {
    size: 1rem; } }

.box--vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
